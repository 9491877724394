<template lang="pug">
  div
    include ../../../configs/template
    div.mt-7.ml-7
      +radio-group('typeDocument','typeDocumentList','typeDoc')(row)
    TableV2(
      :headers="headers"
      :items="isLoading ? [] : items.results"
      :pageCount="items.count"
      :currentPage="items.current"
      :querySearch="getDataReport"
      :isLoading="isLoading"
      :actions="actions"
    )
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { qualDocsHeaders } from './constants'
export default {
  name: 'DocListQualReport',
  data () {
    return {
      actions: [
        { id: 1, tooltip: 'tooltip.go', action: (item) => this.goToDocumentSailor(item), color: 'blue', name: 'mdi-arrow-right', isView: true }
      ],
      headers: qualDocsHeaders,
      typeDocument: 'diplomasQualification',
      typeDocumentList: [
        { label: this.$t('qualificationDocs'), value: 'diplomasQualification' },
        { label: this.$t('qualificationStatement'), value: 'statementQualification' }
      ],
      items: [],
      tableLoader: false,
      sortAcs: null,
      sortDesc: null,
      sortName: null,
      typeDoc: null,
      proof: 16
    }
  },
  computed: {
    ...mapState({
      isLoading: state => state.report.isLoading
    })
  },
  watch: {
    typeDocument (newVal, oldVal) {
      if (newVal !== oldVal) this.$router.push('/report/qualification/statement')
    }
  },
  methods: {
    ...mapActions(['getReportQualification']),
    goToDocumentSailor (item) {
      item && this.$router.push({
        name: item.type_document.id === this.proof ? 'qualification-documents-proof-info' : 'qualification-documents-info',
        params: { id: item.sailor.id, documentID: item.id }
      })
    },
    async getDataReport (filter) {
      this.items = await this.getReportQualification(filter)
    }
  }
}
</script>
